import React, { Fragment } from "react";
import { SEO, Footer, Article as ArticleView } from "../components";

export default function Article({ pageContext, location }) {

  const {id, html, frontmatter: { date, description, featuredimage, title } } = pageContext.post;
  const { node } = pageContext.cat;

  const object_post = {
      id,
      category: {
          id: node.id,
          title: node.frontmatter.title,
          featuredimage: node.frontmatter.featuredimage,
          description: node.frontmatter.description
      },
      date,
      title,
      featuredimage,
      description,
      html
  };

  return (
    <Fragment>
      <SEO
        url={location.href}
        description={object_post.description}
        keywords={`blog picap, pibox, pilife, pihome, ${object_post.category}`}
      />
      <ArticleView info={ object_post } view="dontShow" />
      <Footer
        title={object_post.title}
        description={object_post.description}
        img={object_post.featuredimage}
        url={location.href}
      />
    </Fragment>
  );
}
